@import "./assets/layout/styles/layout/layout.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";

.invalid {
  border-color: rgb(193, 25, 25) !important;
}

.active-route {
  border: solid 2px #495057;
}

.p-dialog .p-dialog-header .p-dialog-title {
  display: block;
  margin: auto;
}

.full-grid {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  position: relative;
  bottom: 15vh;
}

.margin-col-start {
  padding-inline-start: 2vw;
}

.margin-col-end {
  padding-inline-end: 2vw;
}

// .accordion-indicator {
//   .p-accordion {
//     min-width: 40vw;
//   }
// }

.multiselect-form {
  .p-multiselect {
    width: 100%;
  }
}

.calendar-form {
  .p-calendar {
    width: 100%;
    // Dia seleccionado
    .p-datepicker table td > span.p-highlight {
      color: white;
      background: #007bff;
    }
  }
}

.pdf-container {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
}

.label-form {
  margin-bottom: 1vh;
}

.spinner-container-user{
  margin-left: 1vw;
  ion-spinner {
    height: 50px;
    width: 50px;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 100px;
    width: 100px;
  }
}

.spinner-container-topbar {
  ion-spinner {
    height: 50px;
    width: 50px;
  }
}

.spinner-container-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 60px;
    width: 60px;
  }
}

.spinner-container-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 70px;
    width: 70px;
  }
}

.item-loading-indicators {
  --background: white;
  --color: black;
  text-align: center;
}

.spinner-container-indicator {
  display: grid;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 100px;
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  ion-label {
    text-align: center;
  }
}

.p-dropdown {
  width: 100%;
}

div .p-chip .p-chip-text {
  margin: 0.375rem auto;
  min-width: 76px;
  text-align: center;
}

.p-chip {
  margin: 3px 0;
}

.custom-chip-primary {
  .p-chip {
    background: #007bff; /* Color de fondo primario */
    color: white;
  }
}

.custom-chip-secondary {
  .p-chip {
    background: #6c757d; /* Color de fondo secundario */
    color: white;
  }
}

.custom-chip-success {
  .p-chip {
    background: #28a745; /* Color de fondo de éxito */
    color: white;
  }
}

.custom-chip-danger {
  .p-chip {
    background: #dc3545; /* Color de fondo de peligro */
    color: white;
  }
}

.custom-chip-warning {
  .p-chip {
    background: #ffc107; /* Color de fondo de advertencia */
    color: black; /* Color de texto */
  }
}

.custom-chip-info {
  .p-chip {
    background: #17a2b8; /* Color de fondo de información */
    color: white;
  }
}

.custom-chip-light {
  .p-chip {
    background: #f8f9fa; /* Color de fondo claro */
    color: black;
  }
}

.custom-chip-dark {
  .p-chip {
    background: #343a40; /* Color de fondo oscuro */
    color: white;
  }
}

.custom-chip-orange {
  .p-chip {
    background: #ffa600; /* Color de fondo oscuro */
    color: white;
  }
}

// Buscador
.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.p-button-success {
  .p-button {
    background-color: #28a745;
    border-color: #28a745;
  }
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: #f5f5f5;
  color: #495057;
  overflow: hidden;
  margin-top: 1vh;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #e8e8e8;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.container-img,
.p-field {
  display: grid;
  align-items: center;
  justify-content: center;
}

.avatar-img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

.p-datatable {
  th,
  tr {
    text-align: center;
  }
}

.text-field-center {
  text-align: center;
}
